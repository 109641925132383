import Vuex from 'vuex';
import Vue from 'vue';

import activities from './modules/activities';
import messages from './modules/messages';
import user from './modules/user';
import rallies from './modules/rallies';
import channels from './modules/channels';
import calendars from './modules/calendars';
import contents from './modules/contents';
import locations from './modules/locations';
import rfins from './modules/rfins';
import memberships from './modules/memberships';
import notifications from './modules/notifications'
import participations from './modules/participations';
import sentiments from './modules/sentiments'
import shares from './modules/shares';
import tags from './modules/tags';

import channels_v2 from "./modules/channels_v2";
import memberships_v2 from "./modules/memberships_v2";
import rallies_v2 from './modules/rallies_v2';

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

// Load Vuex
Vue.use(Vuex);

// Create store
export const store = new Vuex.Store({ 
  modules: {
    activities,
    messages,
    user,
    rallies,
    channels,
    calendars,
    contents,
    rfins,
    memberships,
    notifications,
    participations,
    sentiments,
    shares,
    tags,
    locations,
    channels_v2,
    memberships_v2,
    rallies_v2
  }//,
 // plugins: [createPersistedState()] //TODO: figure out an alternative, causing crashes
/*
  plugins: [createPersistedState({
      key: 'messages',
      paths: ['messages']
  }), createPersistedState({
      key: 'user',
      paths: ['user']
  }),  createPersistedState({
    key: 'rallies',
    paths: ['rallies']
  }),
  createPersistedState({
    key: 'channels',
    paths: ['channels']
  }),
  createPersistedState({
    key: 'calendars',
    paths: ['calendars']
  }),
  createPersistedState({
    key: 'contents',
    paths: ['contents']
  }),
  createPersistedState({
    key: 'rfins',
    paths: ['rfins']
  }),
  createPersistedState({
    key: 'memberships',
    paths: ['memberships']
  }),
  createPersistedState({
    key: 'shares',
    paths: ['shares']
  }),

  //below is a more advanced route
    /*plugins: [    createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  }),],*/

});